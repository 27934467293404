import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js'

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

// ReactDOM.render(<NetEffect />,
// document.querySelector("#effect"))
