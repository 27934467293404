import React, { useState, useEffect } from "react";
import { app, storage} from '../firebase'
import { useNavigate } from "react-router-dom"; 
import { getFirestore, collection, getDocs } from 'firebase/firestore/lite';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './projects.css'
import MenuItems from "../Components/Navbar/MenuItems";
import Navbar from "../Components/Navbar/Navbar";
import {Link} from "react-router-dom";


const  Projects = () => {
    const [loading, setLoading] = useState(true);
    const [blogPosts, setBlogPosts] = useState([]);
    
    const db = getFirestore(app);
    const storage = getStorage();


    useEffect(() => {
        let posts = [];
        const getAllPosts = async () => {
            const querySnapshot = await getDocs(collection(db, "posts"));
            querySnapshot.forEach((doc) => {
                    posts.push(doc.data());
                });    
                const newestFirst = posts.reverse();
                setBlogPosts(newestFirst)
        };
        getAllPosts().catch(console.error);
    },[])

    if (loading && !blogPosts.length) {
        setLoading(false);
    }

    if (loading) {
        return <h1>Loading...</h1>;
    }

    return (
        <>
            <div className="head">
                <a href="/"><h1 className="name">Esinam Dake</h1></a>
                <div className="menu"><MenuItems pageWrapId = {'page-wrap'} outerContainerId={'outer-container'} /></div>
            </div>
            <br/>
            <br/>
            <br/>
            <div className="mainP">
                <h1 className="titleProj">My Projects</h1>

                <div className="pContainer">
                    {blogPosts.map((bPost) => (
                            <section key={bPost.slug} className="card">
                                <Link to={`/${bPost.slug}`}>
                                    {console.log(bPost.slug)}
                                    <img style={{height:"150px", marginTop:"20px"}} src={bPost.coverImage} alt={bPost.coverImageAlt} />
                                    <div className="card-content">
                                        <h3>{bPost.title}</h3>
                                        <h4>{bPost.datePretty}</h4>
                                    </div>
                                </Link>
                            </section>
                        ))}
                </div>
            </div>
            
            <br />
            <br />
            

            <div className="footer">
                <a className="contact" href="/contact-me">Contact Me!</a>
                <div className="bottomNav"><Navbar /></div>
            </div>
        </>
    );
}
export default Projects;
